import { render, staticRenderFns } from "./paste.vue?vue&type=template&id=22605414&scoped=true"
import script from "./paste.vue?vue&type=script&lang=js"
export * from "./paste.vue?vue&type=script&lang=js"
import style0 from "./paste.vue?vue&type=style&index=0&id=22605414&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22605414",
  null
  
)

export default component.exports